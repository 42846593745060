<template>

  <router-view></router-view>
</template>

<script>
export default {
  methods: {
    initializeLayout() {
      if (!this.$rtl.isRTL) {
        // Just make sure rtl css is off when we are not on rtl
        this.$rtl.disableRTL();
      }
    },
  },
  mounted() {
    this.initializeLayout();
  },
  created(){
        // 读取背景颜色
    let docClasses = document.body.classList;
    let type = localStorage.getItem("toggleMode");
    if (type == 0) {
      docClasses.remove("white-content");
    } else {
      docClasses.add("white-content");
    }
    // 读取是否隐藏导航栏
    let minimizeSidebar = localStorage.getItem("minimizeSidebar");
    if (minimizeSidebar == 0) {
      document.body.classList.toggle("sidebar-mini", true);
    } else {
      document.body.classList.toggle("sidebar-mini", false);
    }
  }
};
</script>
