// plugins/loading.js
import { showLoading, closeLoading } from './loadingFun.js';

export default {
  install: (Vue, options) => {
    // 添加实例方法
    Vue.prototype.$loading = {
      show: showLoading,
      close: closeLoading
    };
  }
};
