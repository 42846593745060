var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":"喜乐麻匠管理后台","logo":"img/icon-vue.png"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: '主页',
          icon: 'tim-icons icon-bullet-list-67',
          path: '/home',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: '用户管理',
          icon: 'tim-icons icon-single-02',
          path: '/user',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: '包间管理',
          icon: 'tim-icons icon-bank',
          path: '/room',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: '包间状态',
          icon: 'tim-icons icon-planet',
          path: '/roomState',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: '支付管理',
          icon: 'tim-icons icon-cart',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: '在线支付',
            path: '/pay',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: '团购支付',
            path: '/payTuan',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: '续单支付',
            path: '/payContinue',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: '美团兑换',
            path: '/meiTuan',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: '优惠管理',
          icon: 'tim-icons icon-gift-2',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: '用户团购券',
            path: '/userTuangou',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: '预定管理',
          icon: 'tim-icons icon-time-alarm',
          path: '/reservation',
        }}})],1)],2),_c('sidebar-share',{attrs:{"background-color":_vm.sidebarBackground},on:{"update:backgroundColor":function($event){_vm.sidebarBackground=$event},"update:background-color":function($event){_vm.sidebarBackground=$event}}}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }