<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      title="喜乐麻匠管理后台"
      logo="img/icon-vue.png"
    >
      <template slot="links">
        <!-- <sidebar-item
          :link="{ name: $t('sidebar.pages'), icon: 'tim-icons icon-image-02' }"
        >
          <sidebar-item
            :link="{ name: $t('sidebar.pricing'), path: '/pricing' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.rtl'), path: '/pages/rtl' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.timeline'), path: '/pages/timeline' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.login'), path: '/login' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.register'), path: '/register' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.lock'), path: '/lock' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.userProfile'), path: '/pages/user' }"
          ></sidebar-item>
        </sidebar-item> -->
        <sidebar-item
          :link="{
            name: '主页',
            icon: 'tim-icons icon-bullet-list-67',
            path: '/home',
          }"
        ></sidebar-item>

        <!-- <sidebar-item
          :link="{
            name: '套餐管理',
            icon: 'tim-icons icon-bag-16',
            path: '/package',
          }"
        ></sidebar-item> -->
        <!-- <sidebar-item
          :link="{
            name: '网站设置',
            icon: 'tim-icons icon-settings-gear-63',
            path: '/settings',
          }"
        ></sidebar-item> -->
        <!-- <sidebar-item
          :link="{
            name: '网站管理',
            icon: 'tim-icons icon-atom',
            path: '/site',
          }"
        ></sidebar-item> -->
        <sidebar-item
          :link="{
            name: '用户管理',
            icon: 'tim-icons icon-single-02',
            path: '/user',
          }"
        ></sidebar-item>
        <!-- <sidebar-item
          :link="{
            name: '设备管理',
            icon: 'tim-icons icon-alert-circle-exc',
            path: '/device',
          }"
        ></sidebar-item> -->
        <!-- <sidebar-item
          :link="{
            name: '店铺管理',
            icon: 'tim-icons icon-align-center',
            path: '/store',
          }"
        ></sidebar-item> -->
        <sidebar-item
          :link="{
            name: '包间管理',
            icon: 'tim-icons icon-bank',
            path: '/room',
          }"
        ></sidebar-item>
                <sidebar-item
          :link="{
            name: '包间状态',
            icon: 'tim-icons icon-planet',
            path: '/roomState',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: '支付管理',
            icon: 'tim-icons icon-cart',
          }"
        >
          <sidebar-item
            :link="{
              name: '在线支付',
              path: '/pay',
            }"
          ></sidebar-item>
          <!-- <sidebar-item
            :link="{
              name: '余额支付',
              path: '/payBalance',
            }"
          ></sidebar-item> -->
          <sidebar-item
            :link="{
              name: '团购支付',
              path: '/payTuan',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: '续单支付',
              path: '/payContinue',
            }"
          ></sidebar-item>
                    <sidebar-item
            :link="{
              name: '美团兑换',
              path: '/meiTuan',
            }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: '优惠管理',
            icon: 'tim-icons icon-gift-2',
          }"
        >
          <sidebar-item
            :link="{
              name: '用户团购券',
              path: '/userTuangou',
            }"
          ></sidebar-item>
          <!-- <sidebar-item
            :link="{
              name: '用户优惠券',
              path: '/userIntegral',
            }"
          ></sidebar-item> -->
        </sidebar-item>
        <sidebar-item
          :link="{
            name: '预定管理',
            icon: 'tim-icons icon-time-alarm',
            path: '/reservation',
          }"
        ></sidebar-item>
        <!-- <sidebar-item
          :link="{
            name: '卡密管理',
            icon: 'tim-icons icon-map-big',
            path: '/cdk',
          }"
        ></sidebar-item> -->
        <!-- <sidebar-item
          :link="{
            name: '余额管理',
            icon: 'tim-icons icon-coins',
            path: '/balance',
          }"
        ></sidebar-item> -->
        <!-- <sidebar-item
          :link="{
            name: '优惠券管理',
            icon: 'tim-icons icon-tie-bow',
            path: '/integral',
          }"
        ></sidebar-item> -->
        <!-- <sidebar-item
          :link="{
            name: '团购券管理',
            icon: 'tim-icons icon-shape-star',
            path: '/tuangou',
          }"
        ></sidebar-item> -->
        <!-- <sidebar-item
          :link="{
            name: '轮播图管理',
            icon: 'tim-icons icon-calendar-60',
            path: '/banner',
          }"
        ></sidebar-item> -->
      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <sidebar-share :background-color.sync="sidebarBackground"> </sidebar-share>
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import SidebarShare from "./SidebarSharePlugin";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import SidebarFixedToggleButton from "./SidebarFixedToggleButton.vue";
import { ZoomCenterTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    ZoomCenterTransition,
    SidebarShare,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("sidebar");
        initScrollbar("main-panel");
        initScrollbar("sidebar-wrapper");

        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
