<template>
  <div class="fixed-plugin" v-click-outside="closeDropDown">
    <div class="dropdown show-dropdown" :class="{ show: isOpen }">
      <a data-toggle="dropdown" class="settings-icon">
        <i class="fa fa-cog fa-2x" @click="toggleDropDown"> </i>
      </a>
      <ul class="dropdown-menu" :class="{ show: isOpen }">
        <li class="header-title">主题颜色</li>
        <li class="adjustments-line">
          <a class="switch-trigger background-color">
            <div class="badge-colors text-center">
              <span
                v-for="item in sidebarColors"
                :key="item.color"
                class="badge filter"
                :class="[`badge-${item.color}`, { active: item.active }]"
                :data-color="item.color"
                @click="changeSidebarBackground(item)"
              ></span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>

        <li class="header-title">导航栏</li>
        <li class="adjustments-line">
          <div class="togglebutton switch-sidebar-mini">
            <span class="label-switch">隐藏</span>
            <base-switch
              v-model="sidebarMini"
              @input="minimizeSidebar"
            ></base-switch>
            <span class="label-switch label-right">显示</span>
          </div>

          <div class="togglebutton switch-change-color mt-3">
            <span class="label-switch">白天</span>
            <base-switch v-model="darkMode" @input="toggleMode"></base-switch>
            <span class="label-switch label-right">暗黑</span>
          </div>
        </li>

        <li class="button-container mt-4">
          <a
            href="https://demos.creative-tim.com/vue-black-dashboard-pro/documentation"
            target="_blank"
            rel="noopener"
            class="btn btn-default btn-block btn-round"
          >
            Documentation
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { BaseSwitch } from "src/components";

export default {
  name: "sidebar-share",
  components: {
    BaseSwitch,
  },
  props: {
    backgroundColor: String,
  },
  data() {
    // 储存主题颜色
    let darkModeType = localStorage.getItem("toggleMode");
    let darkMode = true;
    if (darkModeType == 0) {
      darkMode = true;
    } else {
      darkMode = false;
    }

    // 是否隐藏导航栏
    let minimizeSidebar = localStorage.getItem("minimizeSidebar");
    let sidebarMini = true;
    if (minimizeSidebar == 0) {
      sidebarMini = false;
    } else {
      sidebarMini = true;
    }
    return {
      sidebarMini,
      darkMode,
      isOpen: false,
      sidebarColors: [
        { color: "primary", active: false, value: "primary" },
        { color: "vue", active: true, value: "vue" },
        { color: "info", active: false, value: "blue" },
        { color: "success", active: false, value: "green" },
        { color: "warning", active: false, value: "orange" },
        { color: "danger", active: false, value: "red" },
      ],
    };
  },
  created() {
        // 设置导航栏颜色
    let backgroundColor = localStorage.getItem("backgroundColor");
    this.$emit('update:backgroundColor', backgroundColor || 'vue');
  },
  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen;
    },
    closeDropDown() {
      this.isOpen = false;
    },
    toggleList(list, itemToActivate) {
      list.forEach((listItem) => {
        listItem.active = false;
      });
      itemToActivate.active = true;
    },
    changeSidebarBackground(item) {  // 设置导航栏颜色
      localStorage.setItem("backgroundColor",item.value)
      this.$emit("update:backgroundColor", item.value);
      this.toggleList(this.sidebarColors, item);
    },
    toggleMode(type) {
      let docClasses = document.body.classList;
      if (type) {
        localStorage.setItem("toggleMode", 0);
        docClasses.remove("white-content");
      } else {
        localStorage.setItem("toggleMode", 1);
        docClasses.add("white-content");
      }
    },
    minimizeSidebar() { // 是否隐藏导航栏
      if (this.sidebarMini) {
        localStorage.setItem("minimizeSidebar",1);
      } else {
        localStorage.setItem("minimizeSidebar",0);
      }
      this.$sidebar.toggleMinimize();
     
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/assets/sass/dashboard/custom/variables";

.settings-icon {
  cursor: pointer;
}

.badge-vue {
  background-color: $vue;
}
</style>
