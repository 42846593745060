import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';


  // 用户管理
const UserView = () =>
  import( /* webpackChunkName: "extra" */ 'src/pages/UserInfo/UserView.vue');
// 主页
const HomeView = () =>
  import( /* webpackChunkName: "extra" */ 'src/pages/Home/HomeView.vue');
// 预定管理
const ReservationView = () =>
  import( /* webpackChunkName: "extra" */ 'src/pages/ReservationInfo/ReservationView.vue');
// 套餐管理
// import Package from 'src/pages/Package/PackageView.vue';

// 在线支付管理
const PayView = () =>
  import( /* webpackChunkName: "extra" */ 'src/pages/PayInfo/PayView.vue');
// // 余额支付管理
// const PayBalance = () =>
//   import( /* webpackChunkName: "extra" */ 'src/pages/PayBalance/PayBalanceView.vue');
// 团购券支付管理
const PayTuan = () =>
  import( /* webpackChunkName: "extra" */ 'src/pages/PayTuan/PayTuanView.vue');
// 续单支付管理
const PayContinue = () =>
  import( /* webpackChunkName: "extra" */ 'src/pages/PayContinue/PayContinueView.vue');

  // 美团兑换管理
  const MeiTuan = () =>
  import( /* webpackChunkName: "extra" */ 'src/pages/MeiTuan/MeiTuanView.vue');

// 用户优惠券管理
// const UserIntegral = () =>
//   import( /* webpackChunkName: "extra" */ 'src/pages/UserIntegral/UserIntegralView.vue');
// 用户团购券管理
const UserTuangou = () =>
  import( /* webpackChunkName: "extra" */ 'src/pages/UserTuangou/UserTuangouView.vue');

// 店铺管理
// const Store = () =>
// import( /* webpackChunkName: "extra" */ 'src/pages/Store/StoreView.vue');



// 包间管理
const Room = () =>
import( /* webpackChunkName: "extra" */ 'src/pages/Room/RoomView.vue');

const RoomState = () =>
import( /* webpackChunkName: "extra" */ 'src/pages/RoomState/RoomStateView.vue');

// 登录界面
const Login = () =>
  import( /* webpackChunkName: "pages" */ 'src/pages/Pages/Login.vue');



// 优惠券管理
const Integral = () =>
  import( /* webpackChunkName: "pages" */ 'src/pages/Integral/IntegralView.vue');
// 团购券管理
const Tuangou = () =>
  import( /* webpackChunkName: "pages" */ 'src/pages/Tuangou/TuangouView.vue');

// 团购券管理
const Balance = () =>
  import( /* webpackChunkName: "pages" */ 'src/pages/Balance/BalanceView.vue');

// 团购券管理
const Banner = () =>
  import( /* webpackChunkName: "pages" */ 'src/pages/Banner/BannerView.vue');



// // 设备管理
// const Device = () =>
// import( /* webpackChunkName: "extra" */ 'src/pages/Device/DeviceView.vue');

// // 网站设置
// const SettingsView = () =>
//   import( /* webpackChunkName: "extra" */ 'src/pages/Settings/SettingsView.vue');

// // 网站管理
// const SiteView = () =>
//   import( /* webpackChunkName: "extra" */ 'src/pages/Site/SiteView.vue');



let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [{
    path: '/login',
    name: 'Login',
    component: Login
  }]
};

let pays = {
  path: '/',
  redirect: '/pay',
  component: DashboardLayout,
  name: '支付管理',
  children: [{
      path: 'pay',
      name: '在线支付',
      components: {
        default: PayView
      }
    },
    // {
    //   path: 'payBalance',
    //   name: '余额支付',
    //   components: {
    //     default: PayBalance
    //   }
    // },
    {
      path: 'payContinue',
      name: '续单支付',
      components: {
        default: PayContinue
      }
    },
    {
      path: 'payTuan',
      name: '团购支付',
      components: {
        default: PayTuan
      }
    },
    {
      path: 'meiTuan',
      name: '美团兑换',
      components: {
        default: MeiTuan
      }
    }
  ]
};

let youHuis = {
  path: '/',
  redirect: '/userTuangou',
  component: DashboardLayout,
  name: '优惠管理',
  children: [{
      path: 'userTuangou',
      name: '用户团购券',
      components: {
        default: UserTuangou
      }
    },
    // {
    //   path: 'userIntegral',
    //   name: '用户优惠券',
    //   components: {
    //     default: UserIntegral
    //   }
    // }
  ]
};

const routes = [{
    path: '/',
    redirect: '/home',
    name: 'Home1'
  },
  pays,
  authPages,
  youHuis,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [
      // {
      //   path: 'settings',
      //   name: '网站设置',
      //   components: {
      //     default: SettingsView
      //   }
      // },
      // {
      //   path: 'site',
      //   name: '网站管理',
      //   components: {
      //     default: SiteView
      //   }
      // },
      // {
      //   path: 'device',
      //   name: '设备管理',
      //   components: {
      //     default: Device
      //   }
      // },
      // {
      //   path: 'package',
      //   name: '套餐管理',
      //   components: {
      //     default: Package
      //   }
      // },
      // {
      //   path: 'integral',
      //   name: '优惠券管理',
      //   components: {
      //     default: Integral
      //   }
      // },
      // {
      //   path: 'tuangou',
      //   name: '团购券管理',
      //   components: {
      //     default: Tuangou
      //   }
      // },
      {
        path: 'user',
        name: '用户管理',
        components: {
          default: UserView
        }
      },
      // {
      //   path: 'store',
      //   name: '店铺管理',
      //   components: {
      //     default: Store
      //   }
      // },
      // {
      //   path: 'balance',
      //   name: '余额管理',
      //   components: {
      //     default: Balance
      //   }
      // },
      // {
      //   path: 'banner',
      //   name: '轮播图管理',
      //   components: {
      //     default: Banner
      //   }
      // },
      {
        path: 'room',
        name: '包间管理',
        components: {
          default: Room
        }
      },
      {
        path: 'roomState',
        name: '包间状态',
        components: {
          default: RoomState
        }
      },
      {
        path: 'reservation',
        name: '预定管理',
        components: {
          default: ReservationView
        }
      },
      
      {
        path: 'home',
        name: '主页',
        components: {
          default: HomeView
        }
      },

    ]
  },
  {
    path: '*',
    component: NotFound
  }
];

export default routes;
